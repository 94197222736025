<template>
  <el-card>
    <div>
      <Header :profile="profile" :email="email" />
      <hr class="divider" />
      <table class="table table-inbox table-hover" v-if="messages.length > 0">
        <tbody>
          <tr
            v-for="(message, index) in messages"
            @click="toggleAccordion(message, message.threadId)"
            :key="message.id || index"
            :class="{
              unread: typeof message.isRead !== 'undefined' && !message.isRead,
            }"
          >
            <td>
              <a
                href="#"
                v-if="typeof message.isImportant !== 'undefined'"
                @click.prevent.stop="message.isImportant = !message.isImportant"
              >
                <i
                  :class="['fa', 'fa-star', { important: message.isImportant }]"
                ></i>
              </a>
            </td>
            <td>
              <span v-if="message.type === 'sent' || message.type === 'draft'">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="profile.name"
                  placement="top"
                >
                  <span>{{ profile.name | truncate(15, "...") }}</span>
                </el-tooltip>
              </span>
              <span v-else>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="message.from.name"
                  placement="top"
                >
                  <span>{{ message.from.name | truncate(15, "...") }}</span>
                </el-tooltip>
              </span>
            </td>

            <td>{{ message.subject }}</td>
            <td>
              <i
                v-if="message.attachments.length > 0"
                class="fa fa-paperclip"
              ></i>
            </td>
            <td class="text-right">{{ getDateValues(message.date) }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else>No messages here yet.</p>
    </div>
    <el-drawer
      title="I am the title"
      :visible.sync="emailHistory"
      :with-header="false"
      size="60%"
      :before-close="handleClose"
      :destroy-on-close="true"
    >
      <EmailHistory
        :thread="activeThread"
        :threadInfo="activeMessage"
        :companyConfig="companyMailConfig"
        :userConfig="loginUserMailConfig"
        :profile="profile"
      ></EmailHistory>
    </el-drawer>
  </el-card>
</template>

<script>
import { mapGetters } from "vuex";
//   import { bus } from "../../../main";
import { postAPICall } from "@/helpers/httpHelper"; // Use your HTTP helper
import Config from "../../config/app";
import axios from "axios";
import moment from "moment";

import {
  fetchInboxMessages,
  fetchProfile,
} from "../../components/user-integrations/KF-mail/data/messages";
export default {
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      particles: [],
      particleCount: 280,
      messages: [],
      accounts: [],
      showEmails: false,
      isAuthenticated: false,
      companyMailConfig: {},
      loginUserMailConfig: {},
      queryData: null,
      profile: {
        name: "",
        emailAddress: "",
        photo: "",
      },
      emailHistory: false,

      activeThread: null,
      activeMessage: null,

      openIndex: null,
      descriptionHeight: 0,
    };
  },
  components: {
    Header: () => import("./email-profile.vue"),
    EmailHistory: () => import("./email-preview.vue"),
  },
  computed: {
    ...mapGetters("auth", ["getActiveWorkspace", "getAuthenticatedUser"]),
    getDateValues() {
      return (date) => {
        return moment(date).fromNow();
      };
    },
  },
  watch: {
    async queryData(newValue) {
      if (newValue) {
        await this.getTokensUsingAuthorizationCode(newValue);
      }
    },
  },
  async mounted() {
    await Promise.all([this.getAccounts(), this.getConfigurations()]);
  },
  methods: {
    handleClose() {
      this.activeThread = null;
      this.activeMessage = null;
      this.emailHistory = !this.emailHistory;
    },
    toggleAccordion(message, threadId) {
      this.activeThread = threadId;
      this.activeMessage = message;
      this.emailHistory = !this.emailHistory;
      //   this.descriptionHeight=0
      // if (this.openIndex === index) {
      //   this.openIndex = null;
      //   this.descriptionHeight=0
      // } else {
      //   this.openIndex = index;
      //   this.$nextTick(() => {
      //     this.descriptionHeight = this.$refs.descriptions[index].scrollHeight;
      //   });
      // }
    },
    async checkLocalStorage() {
      let storageInfo = await localStorage.getItem("extractCode");
      this.queryData = await JSON.parse(storageInfo);
      await localStorage.removeItem("extractCode");
    },
    async getAccountConnectionList() {
      try {
        this.loading = true;
        this.loadingText = "Loading...";
        let response = await postAPICall(
          "GET",
          "/user_integrations/list?app_type=MAIL"
        );
        // if (response && response.success) {
        if (
          response &&
          response.success &&
          response?.data?.data[0].clientSecret
        ) {
          const decrypted = this.$CryptoJS.AES.decrypt(
            response.data[0].clientSecret,
            "MAIL"
          );
          const info = JSON.parse(decrypted.toString(this.$CryptoJS.enc.Utf8));
          this.accounts = [{ ...info, ...response.data }];
          this.showEmails = this.accounts.length;
        } else {
          this.showEmails = false;
        }
      } catch (err) {
        this.showEmails = false;
      }
    },
    async authenticate(service) {
      let redirectUrl = "";
      const callbackResponse = await axios.get(
        `${Config.KF_EMAIL}/auth/${service}/${this.getActiveWorkspace.company_id}/${this.companyMailConfig._id}/${this.getAuthenticatedUser._id}`
      );
      if (
        callbackResponse &&
        callbackResponse.data &&
        callbackResponse.data.success
      ) {
        redirectUrl = callbackResponse.data.callbackUrl;
        const redirectUri = Config.EMAIL_REDIRECT_URI; // Use the redirect URL from .env
        if (redirectUri) {
          const url = new URL(redirectUrl);
          url.searchParams.set("redirect_uri", redirectUri);
          redirectUrl = url.toString();
        }
        window.open(redirectUrl, "_blank", "width=800,height=600");
      } else {
        redirectUrl = "";
        // return;
      }
    },
    async getTokensUsingAuthorizationCode(query) {
      try {
        if (query.state) {
          query["redirect_uri"] = Config.EMAIL_REDIRECT_URI;
          this.loading = true;
          let tokens = await axios.post(
            `${Config.KF_EMAIL}/${this.companyMailConfig.service}/callback`,
            {
              query,
            }
          );
          console.log("tokens", tokens);
          await Promise.all([this.getAccounts(), this.getConfigurations()]);
          this.$notify.success({
            title: "Success",
            message: "Connected Successfully",
          });
          this.loading = false;
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    async getAccounts() {
      this.loading = true;
      this.loadingText = "Loading configurations ...";
      try {
        let response = await postAPICall("GET", "/user_integrations/list/MAIL");
        if (response && response.success) {
          const decrypted = this.$CryptoJS.AES.decrypt(
            response.data.clientSecret,
            "MAIL"
          );
          const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
          this.companyMailConfig = JSON.parse(info);
          this.companyMailConfig = {
            ...this.companyMailConfig,
            ...response.data,
          };
        }
      } catch (error) {
        console.error(error);

        this.$message.error("Invalid Configuration!");
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getConfigurations() {
      this.loading = true;
      this.loadingText = "Loading User configurations ...";
      try {
        let response = await postAPICall("GET", "/user_integrations/user/MAIL");
        if (response && response.success && response.data.length) {
          this.loginUserMailConfig =
            response.data && response.data.length ? response.data[0] : {};
          const decrypted = this.$CryptoJS.AES.decrypt(
            this.loginUserMailConfig.clientSecret,
            "MAIL"
          );
          const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
          this.loginUserMailConfig = {
            ...this.loginUserMailConfig,
            ...JSON.parse(info),
          };

          setTimeout(async () => {
            await Promise.all([this.getMessages(), this.getUserProfile()]);
          }, 2000);
        }
      } catch (error) {
        this.$message.error("Invalid Configuration!");
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getMessages() {
      try {
        // Fetch messages and assign them to `inbox`
        let data = await fetchInboxMessages(
          this.companyMailConfig?.service,
          this.loginUserMailConfig?._id,
          100,
          0,
          [{ type: "email", value: this.email }]
        );
        console.log("email", this.email);
        this.$set(this, "messages", data.emails);
      } catch (error) {
        console.error("Failed to fetch messages:", error);
      }
    },
    async getUserProfile() {
      try {
        let data = await fetchProfile(
          this.companyMailConfig.service,
          this.loginUserMailConfig._id
        );
        this.profile = data?.profile || {
          name: "",
          emailAddress: "",
          photo: "",
        };
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    },
  },
  async activated() {
    await Promise.all([this.getAccounts(), this.getConfigurations()]);
  },
};
</script>

<style scoped>
.divider {
  height: 2px;
  background: #b7b0b0;
  border-radius: 5%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.accordion {
  width: 100%;
  background: #fff;
  margin: 0 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Table Styling */
.table-inbox {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-inbox th,
.table-inbox td {
  padding: 8px 10px;
  text-align: left;
  vertical-align: middle;
}

.table-inbox tbody tr {
  transition: background-color 0.3s;
  cursor: pointer;
}

.table-inbox tbody tr:hover {
  background-color: #f9f9f9;
}

.table-inbox tbody tr.unread {
  font-weight: 600;
  background-color: #f3f8fc;
}

.table-inbox tbody td:first-child {
  width: 3%;
  text-align: center;
}

.table-inbox tbody td:nth-child(2) {
  text-align: center;
}

.table-inbox tbody td:nth-child(3) {
  padding-left: 10px; /* Add minimal padding to the sender column */
}

.table-inbox tbody td:nth-child(5) {
  width: 5%;
  text-align: center;
}

.table-inbox tbody td.text-right {
  width: 15%;
  text-align: right;
  font-size: 0.9em;
  color: #888;
}

/* Star Icon Styling */
.fa-star {
  color: #ccc;
  transition: color 0.3s;
  font-size: 1em;
}

.fa-star.important {
  color: #ffcc00;
}

.fa-paperclip {
  font-size: 1 em;
  color: #555;
}

/* No Messages Text */
p {
  text-align: center;
  font-size: 1.1em;
  color: #666;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .table-inbox th,
  .table-inbox td {
    padding: 8px 6px;
    font-size: 0.9em;
  }

  .table-inbox tbody td.text-right {
    font-size: 0.8em;
  }

  p {
    font-size: 1em;
  }
}
</style>
